import React from "react";

import SocialMedia from "./infoEmpresa/socialmedia";

const InfoEmpresa = () => (
  <>
    <div className="section">
      <p className="is-size-4">Grupo Aristas, S.L.</p>
    </div>
    <SocialMedia />
  </>
);

export default InfoEmpresa;
