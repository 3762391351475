import React from "react";

const Direccion = () => (
  <>
    <h2 className="subtitle is-4">Dirección</h2>
    <div className="has-text-left">
      <p>Calle La Alberca, 24</p>
      <p>30120 Murcia</p>
    </div>
  </>
);

export default Direccion;
