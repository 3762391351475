import React from "react";

import { FaFacebook, FaInstagram } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import { IconContext } from "react-icons";

const SocialMedia = () => (
  <StaticQuery
    query={graphql`
      query SocialQueryFooter {
        site {
          siteMetadata {
            facebook
            instagram
          }
        }
      }
    `}
    render={data => (
      <IconContext.Provider value={{ className: "icon is-large" }}>
        <article className="columns is-centered">
          <div className="column is-narrow">
            <span className="icons is-larges">
              <a
                href={data.site.siteMetadata.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook color="blue" />
              </a>
            </span>
          </div>
          <div className="column is-narrow">
            <span className="icons is-larges">
              <a
                href={data.site.siteMetadata.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram color="purple" />
              </a>
            </span>
          </div>
        </article>
      </IconContext.Provider>
    )}
  />
);

export default SocialMedia;
