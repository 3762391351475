import React from "react";

const DatosContacto = () => (
  <>
    <h2 className="subtitle is-4">Contacto</h2>
    <div>
      Email:
      <p>
        <a href="mailto:contacto@grupoaristas.com">contacto@grupoaristas.com</a>
      </p>
    </div>
    <div>
      Teléfonos:
      <div>
        <p>
          <a href="tel:968072751">968 072 751</a>
        </p>
        <p>
          <a href="tel:626582341">626 852 341</a>
        </p>
        <p>
          <a href="tel:651571568">651 571 568</a>
        </p>
      </div>
    </div>
  </>
);

export default DatosContacto;
