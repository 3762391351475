import React from "react";
import Link from "gatsby-link";

import "../style.scss";
import { opcionesSubmenu } from "../../datos/opcionesSubmenu";

const SubMenu = () => (
  <div className="hero-head is-hidden-mobile" id="submenu">
    <nav className="navbar">
      <div className="container">
        <div id="navbarMenuHeroA" className="navbar-menu">
          <div className="navbar-end">
            {opcionesSubmenu.map(item => (
              <span className="navbar-item" key={item.key}>
                <Link
                  className="button is-danger is-inverted is-text"
                  to={item.href}
                >
                  <span>{item.name}</span>
                </Link>
              </span>
            ))}
          </div>
        </div>
      </div>
    </nav>
  </div>
);

export default SubMenu;
