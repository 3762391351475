import React from "react";

import Intro from "./secciones/intro";
import Formulario from "./secciones/form";

const TrabajaConNosotros = ({ children }) => (
  <>
    <Intro />
    <Formulario />
  </>
);

export default TrabajaConNosotros;
