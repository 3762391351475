import React from "react";

import FormTitle from "./form/formTitle";
import FormBody from "./form/formBody";

const Formulario = () => (
  <div className="section gradientBg">
    <FormTitle />
    <FormBody />
  </div>
);

export default Formulario;
