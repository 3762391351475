import React, { useState } from "react";

import Link from "gatsby-link";
import "../style.scss";
import aristasLogo from "../../images/aristas-logo.png";
import { menuPrincipal } from "../../datos/menu";
import { opcionesSubmenu } from "../../datos/opcionesSubmenu";

const Navbar = ({ children }) => {
  const [burgerOn, setBurgerOn] = useState("");

  const onBurgerClick = e => {
    e.preventDefault();
    burgerOn === "is-active" ? setBurgerOn("") : setBurgerOn("is-active");
  };

  return (
    <div className="hero-head" id="main-menu">
      <nav className="navbar is-fixed-top navBarGradientBg">
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src={aristasLogo} alt="Logo Aristas" />
              <span className="title has-text-white">Aristas</span>
            </a>
            <a
              role="button"
              className={`navbar-burger burger has-text-white ${burgerOn}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarMenuHeroA"
              href="/#"
              onClick={onBurgerClick}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>

          <div id="navbarMenuHeroA" className={`navbar-menu ${burgerOn}`}>
            <div className="navbar-end">
              {menuPrincipal.map(item => (
                <span className="navbar-item" key={item.key}>
                  <a
                    className="button is-danger"
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon">{item.icon}</span>
                    <span>{item.name}</span>
                  </a>
                </span>
              ))}

              {burgerOn === "is-active" &&
                opcionesSubmenu.map(item => (
                  <span className="navbar-item" key={item.key}>
                    <Link
                      className="button is-danger is-inverted is-text"
                      to={item.href}
                    >
                      <span>{item.name}</span>
                    </Link>
                  </span>
                ))}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;
