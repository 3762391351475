import React from "react";
import {
  FaUserFriends,
  FaUsersCog,
  FaUserTag,
  FaRegEdit
} from "react-icons/fa";

const iconPadres = <FaUserFriends />;
const iconAmpa = <FaUsersCog  />;
const iconMonitores = <FaUserTag  />;
const iconAlta = <FaRegEdit  />;

const menuPrincipal = [
  {
    key: 0,
    name: "PADRES",
    icon: iconPadres,
    href: "https://grupoaristas.simun.es/crm/intranetActividades/login.asp"
  },
  {
    key: 1,
    name: "AMPAS",
    icon: iconAmpa,
    href: "https://grupoaristas.simun.es/crm/intranet/login.asp"
  },
  {
    key: 2,
    name: "Monitores Actividades",
    icon: iconMonitores,
    href: "https://grupoaristas.simun.es/crm/intranetpersonal/login.asp"
  },
  {
    key: 3,
    name: "Alta Online Actividades",
    icon: iconAlta,
    href: "http://grupoaristas.com/inscripciones.html"
  }
];

export { menuPrincipal };
