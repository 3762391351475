import React from "react";

const Intro = ({ children }) => (
  <section className="hero is-small">
    <div className="hero-body has-text-centered">
      <p>
        ¿Te imaginas un trabajo en el que tu mayor objetivo es regalar sonrisas?
      </p>
      <p>
        ¿Descubrirías diferentes lugares de la mano del mejor equipo de
        monitores?
      </p>
      <p>
        ¿Bailarías, saltarías y disfrutarías de cada día como si fuera el mejor
        de tu vida? ¿Sí?
      </p>
      <p>¡Esto es Aristas!</p>
    </div>
  </section>
);

export default Intro;
