import React from "react";

const FormTitle = () => (
  <div className="content has-text-centered">
    <div className="container">
      <p className="title is-1 has-text-white">Trabaja con Aristas</p>
      <div className="columns is-centered">
        <div className="column is-8">
          <p className="subtitle is-4 has-text-white">
            Cuéntanos qué se te da bien, por qué quieres trabajar con nosotros,
            y que es lo que más te gusta de este trabajo
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default FormTitle;
