const opcionesSubmenu = [
  { key: 0, name: "Nosotros", href: "/nosotros" },
  { key: 1, name: "Extraescolares", href: "/extraescolares" },
  { key: 2, name: "Excursiones", href: "/excursiones" },
  { key: 3, name: "Escuelas de vacaciones", href: "/escuelas-vacaciones" },
  { key: 4, name: "Contacto", href: "/contacto" },
  { key: 5, name: "Trabaja con Nosotros", href: "/trabaja-con-nosotros" }
];

export { opcionesSubmenu };
