import React from "react";

import "../../../../style.scss"

const FormBody = () => (
  <div>
    <form
      action="https://formspree.io/recursoshumanos@grupoaristas.com"
      method="POST"
    >
      <input type="hidden" name="_next" value="/trabaja-con-nosotros" />
      <input
        type="hidden"
        name="_subject"
        value="Nuevo contacto desde la web"
      />
      <input type="hidden" name="_language" value="es" />
      <input type="text" name="_gotcha" style={{ display: "none" }} />

      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="content">
              <div className="field animated fadeInUp">
                <label className="label has-text-white">Nombre</label>
                <div className="control has-icons-left">
                  <span className="icon">
                    <i className="far fa-user" />
                  </span>
                  <input className="input" name="nombre" />
                </div>
              </div>

              <div className="field animated fadeInUp">
                <label className="label has-text-white">Teléfono</label>
                <div className="control has-icons-left">
                  <span className="icon">
                    <i className="fas fa-phone" />
                  </span>
                  <input
                    className="input"
                    type="tel"
                    maxLength={9}
                    name="telefono"
                  />
                </div>
              </div>

              <div className="field animated fadeInUp">
                <label className="label has-text-white">
                  Correo electrónico
                </label>
                <div className="control has-icons-left">
                  <span className="icon">
                    <i className="fas fa-at" />
                  </span>
                  <input className="input" type="email" name="email"/>
                </div>
              </div>

              <div className="field animated fadeInUp">
                <label className="label has-text-white">Mensaje</label>
                <div className="control">
                  <textarea className="textarea" />
                </div>
              </div>
              <div className="field is-grouped is-grouped-centered">
                <div className="control animated fadeInUp">
                  <input
                    type="submit"
                    className="button is-warning"
                    value="Enviar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
);

export default FormBody;
