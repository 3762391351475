import React from "react";

import InfoEmpresa from "./footer/infoEmpresa";
import Direccion from "./footer/direccion";
import DatosContacto from "./footer/datosContacto";
import Enlaces from "./footer/enlaces";

const Footer = () => (
  <>
    <footer className="footer center section has-background-light">
      <div className="columns">
        <div className="column is-narrow has-text-centered">
          <InfoEmpresa />
          <div>
            <p className="is-size-5">© Grupo Aristas, S.L.</p>
          </div>
        </div>
        <div className="column">
          <Direccion />
        </div>
        <div className="column">
          <DatosContacto />
        </div>
        <div className="column">
          <Enlaces />
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
