import React from "react";
import Link from "gatsby-link"


const links = [
  {
    key: 0,
    texto: "Excursiones",
    to: "/excursiones"
  },
  {
    key: 1,
    texto: "Extraescolares",
    to: "/extraescolares"
  },
  {
    key: 2,
    texto: "Escuelas de Vacaciones",
    to: "/escuelas-vacaciones"
  },
  {
    key: 3,
    texto: "Privacidad y Cookies",
    to: "/privacidad-cookies"
  }
];

const Enlaces = () => (
  <>
    <h2 className="subtitle is-4">Enlaces</h2>

    {links.map(link => (
      <p key={link.key} className="has-text-left">
        <Link to={link.to}>{link.texto}</Link>
      </p>
    ))}
  </>
);

export default Enlaces;
